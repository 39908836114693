<template>
  <div>
    <el-card class="box-card">
      <el-table
          :data="inspect"
          border
          stripe
          style="width: 100%"
      >
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="imei" prop="deviceId"></el-table-column>
        <el-table-column label="iccid" prop="imei"></el-table-column>
        <el-table-column label="体温" prop="temp"></el-table-column>
        <el-table-column label="咳嗽" prop="switch1"></el-table-column>
        <el-table-column label="指甲" prop="switch2"></el-table-column>
        <el-table-column label="流涕" prop="switch3"></el-table-column>
        <el-table-column label="喉炎" prop="switch4"></el-table-column>
        <el-table-column label="测量时间" prop="time" width="160"></el-table-column>
      </el-table>
      <el-pagination
          :page-size="formInline.size"
          :page-sizes="[10, 20, 50]"
          :total="listCount"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          fixed="right"
          layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
import user from "../model/user";

export default {
  name: "user",
  data() {
    return {
      dialogFormVisible: false,
      formInline: {
        page: 1,
        size: 10
      },
      inspect: [],
      listCount: 0
    }
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      const ret = await user.inspect(this.formInline)
      if (ret.code === 200) {
        this.inspect = ret.data.list;
        this.listCount = ret.data.count;
      }
    },
    resetCode(row) {
      this.dialogFormVisible = true;
      this.resetform.uid = row.uid;
      this.resetform.name = row.name;
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.loadData();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.card-header {
  display: flex;
  align-items: center;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}
</style>
